import React from 'react';
export var CmsComponentsContext = React.createContext({
    prodOverview: {
        prodOverViewSections: [],
        setProdOverViewSections: function (value) { }
    },
    showFBTSection: false,
    setShowFBTSection: function (value) { },
    isShowFBTSection: false,
    setIsShowFBTSection: function (value) { },
    frequentlyBoughtData: [],
    setFrequentlyBoughtData: function (data) { },
    openSignInModal: false,
    setOpenSignInModal: function (value) { },
    monetateCarousalData: {},
    setMonetateCarousalData: function (value) { },
    isSddSuccess: false,
    setSddSuccess: function (value) { },
    btnAddToCartName: 'ADD TO CART',
    setBtnAddToCartName: function (value) { },
    isInClubItem: false,
    setIsInClubItem: function (value) { },
    isDigitalGiftCard: false,
    setDigitalGiftCard: function (value) { },
    giftCardError: {
        digital: false,
        physical: false
    },
    setGiftCardError: function (value) { },
    isSuccessSignin: false,
    setIsSuccessSignin: function (value) { },
    isGrocery: false,
    sortedDescriptiveAttributes: [],
    setIsGrocery: function (value) { },
    setSortedDescriptiveAttributes: function (value) { },
    activeCoupons: [],
    count: 1,
    setCount: function (value) { },
    kiboClubId: "",
    setKiboClubId: function (value) { },
    signInModalVisible: false,
    setSignInModalVisible: function (value) { },
    addToCartVisible: false,
    setAddToCartVisible: function (value) { },
    cartResponse: [],
    setAddCartResponse: function (value) { },
    isSignedIn: false,
    setIsSignedIn: function (value) { },
    addCartRef: null,
    fbtArticle: {
        ID: ''
    },
    setFbtArticle: function (value) { }
});
