import { format } from "date-fns";
export var getSelectionValue = function (arg) {
    return arg.selectionValues[0].key;
};
export var setSelectionValue = function (arg, value) {
    arg.selectionValues[0].key = value;
};
export var textToHorizontalFlex = function (alignment) {
    if (alignment === 'Left')
        return 'start';
    if (alignment === 'Right')
        return 'end';
    return 'center';
};
export var getColor = function (color) {
    if (color === 'Red')
        return '#cc0033';
    if (color === 'GrayBlack')
        return '#303030';
    return '#ffffff';
};
export var getSelectionLabel = function (arg) {
    return arg.selectionValues[0].label;
};
export var getFormatedDateTime = function () {
    return format(new Date(), "MMM dd, yyyy, hh:mm:ss a");
};
