import { environmentConst as environment } from '@config/environments/constants';
var useClearanceBadge = function (breadcrumbList, merchandiseCatagoryIdentifier) {
    if (breadcrumbList === void 0) { breadcrumbList = []; }
    if ((environment === null || environment === void 0 ? void 0 : environment.MERCH_CATEGORY_IDENTIFIER) == merchandiseCatagoryIdentifier) {
        return false;
    }
    return breadcrumbList === null || breadcrumbList === void 0 ? void 0 : breadcrumbList.some(function (item) {
        var _l1$split$;
        var l1 = item['1'];
        return typeof l1 === 'string' && ((_l1$split$ = l1.split('|')[0]) === null || _l1$split$ === void 0 || (_l1$split$ = _l1$split$.trim()) === null || _l1$split$ === void 0 ? void 0 : _l1$split$.toLocaleLowerCase()) === 'clearance';
    });
};
export default useClearanceBadge;
