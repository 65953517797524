import styled from 'styled-components';
// Styled Wrapper for Product Highlights
export var ProductHighlightsWrap = styled.div.withConfig({
    displayName: "ProductHighlightsstyle__ProductHighlightsWrap",
    componentId: "sc-n6to60-0"
})(["border:1px solid #e2e2e2;border-radius:5px;padding:10px;margin:", ";h2{font-size:20px;font-weight:600;color:#303030;}.read-more{font-size:14px;color:#303030;text-decoration:underline;margin-top:10px;display:block;cursor:pointer;}"], function (props) { return props !== null && props !== void 0 && props.isCMS ? '0 0 16px 0' : '10px 0'; });
// Styled Table
export var StyledTable = styled.table.withConfig({
    displayName: "ProductHighlightsstyle__StyledTable",
    componentId: "sc-n6to60-1"
})(["border:0;margin:0;font-size:14px;th{font-weight:normal;width:50%;}th,td{padding:5px 0.75rem 5px 0;border:none;font-size:14px;}.specs-table-heading{font-weight:500;}"]);
