export var inClubPricingMessage = function (formatedDateTime) {
    return "We do our best to update club pricing and inventory amounts as they change. However, there may be slight differences in actual club pricing and inventory levels. Therefore, the price reflected and the inventory is estimated as of " + formatedDateTime + " and does not include taxes or application of coupons.\n    Coupons are subject to valid dates and the terms of the coupon offer.";
};
// PDP 
export var maxAllowedMsg = 'The initial quantity selected exceeds the maximum allowed. The quantity has been reset to the maximum allowed.';
export var unableATCMsg = 'Unable to add to cart';
// CMS default data
export var couponsCMSPosition = {
    couponsDefaultPosition: "1",
    offerDefaultPosition: "2"
};
export var priceCMSPosition = {
    priceDefaultPosition: "1",
    savingsDefaultPosition: "2",
    badgeDefaultPosition: "3",
    payPalDefaultPosition: "4"
};
export var imageTrayDefaultPosition = "Left";
export var cmsBorderPosition = {
    noBorder: "none",
    topBorder: "above",
    bottomBorder: "below"
};
